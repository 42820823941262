<template>
  <div class="addEkskulParticipant">
    <template v-if="generalData.ekstrakurikuler">
      <a-modal
        :visible="showConfirmAdd"
        :confirm-loading="loading.updateLoading"
        @ok="handleOkAddParticipants"
        @cancel="showConfirmAdd = false"
        :maskStyle="{
          opacity: 0.5,
          backgroundColor: 'black',
        }"
        okText="Add"
        :okButtonProps="{
          props: {
            shape: 'round',
          },
        }"
        :cancelButtonProps="{
          props: {
            shape: 'round',
          },
        }"
      >
        <h3 slot="title" style="color: #faad14">
          <a-icon style="font-size: 1.2em" type="warning" /> ADD NEW
          EXTRACURRICULAR PARTICIPANTS
        </h3>
        <p>
          Are you sure to add new participants on
          {{ generalData.ekstrakurikuler.nama }}?
        </p>
      </a-modal>
      <a-modal
        :visible="showConfirmRemove"
        :confirm-loading="loading.updateLoading"
        @ok="handleOkRemoveParticipants"
        @cancel="showConfirmRemove = false"
        :maskStyle="{
          opacity: 0.5,
          backgroundColor: 'black',
        }"
        okText="Remove"
        :okButtonProps="{
          props: {
            shape: 'round',
            type: 'danger',
          },
        }"
        :cancelButtonProps="{
          props: {
            shape: 'round',
          },
        }"
      >
        <h3 slot="title" style="color: #faad14">
          <a-icon style="font-size: 1.2em" type="warning" /> REMOVE
          EXTRACURRICULAR PARTICIPANTS
        </h3>
        <p>
          Are you sure to remove {{ removedStudentsData.length }} participants
          on {{ generalData.ekstrakurikuler.nama }}?
        </p>
      </a-modal>
      <div class="coverDetail">
        <h2>Extracurricular | {{ generalData.ekstrakurikuler.nama }}</h2>
        <div style="color: #ffffff">
          {{ generalData.tahunAjaran.tahun }}
          {{ generalData.tahunAjaran.semester }}
        </div>
      </div>
      <div class="contentDetail">
        <div class="d-flex flex-column flex-md-row align-items-center">
          <div class="w-100 w-lg-75">
            <a-divider orientation="left">
              <h3>Students By Class</h3>
            </a-divider>
          </div>
          <div class="w-auto d-flex flex-column flex-md-row ml-3">
            <a-button
              :disabled="loading.loadingTable"
              @click.prevent="refreshTable"
              shape="round"
              class="mb-3 mb-lg-0 mr-0 mr-md-3"
            >
              <a-icon type="sync" :spin="loading.loadingRefresh" />
              REFRESH</a-button
            >
            <!-- <a-button
              @click.prevent="showConfirmAdd = true"
              shape="round"
              :disabled="!selectedStudents.length"
              type="primary"
              icon="save"
              class="mb-3"
              >SAVE CHANGES</a-button
            > -->
          </div>
        </div>
        <a-row>
          <!-- <a-col class="d-md-flex justify-content-md-center" :lg="10" :md="24">
            <a-switch @change="showMuridSesi = !showMuridSesi" />
          </a-col>
          <a-col class="d-md-flex justify-content-md-center justify-content-lg-left" :lg="14" :md="24">
            <div class="mx-2 mb-3" v-for="(note, i) in notes[reportType]" :key="i">{{note.code}}: {{note.full}}</div>
            <div class="mx-2 mb-3" >Avg: Average</div>
          </a-col> -->
          <!-- <a-col :span='24'>
            <a-collapse class="mb-3" accordion>
              <a-collapse-panel key="1" header="Description">
                <ul>
                  <li>You can only update report between the time that is defined by admin or vice principal of curriculum.</li>
                  <li>You can press <kbd>tab</kbd> &nbsp; on your keyboard to move on next columns when updating grade.</li>
                  <li>You can only input on each sub KD, PTS, and PAS/PAT skill. PTS, PAS/PAT knowledge are according to CBT's grade on each type.</li>
                  <li>Grade on each cell can be empty, and each kd average will automatically calculate unempty grade.</li>
                </ul>
                <div class="text-center mt-4"><b>NOTES: </b></div>
                <div class="d-md-flex justify-content-md-center justify-content-lg-left">
                  <div class="mx-2 mb-3" v-for="(note, i) in notes[reportType]" :key="i">{{note.code}}: {{note.full}}</div>
                  <div class="mx-2 mb-3" >Avg: Average</div>
                </div>
              </a-collapse-panel>
            </a-collapse>
          </a-col> -->
        </a-row>
        <a-row
          :type="isMobile ? null : 'flex'"
          align="middle"
          class="mb-3"
          :gutter="[16, 16]"
        >
          <a-col :lg="5" :sm="24">
            <!-- <div>LEVEL</div> -->
            <a-select
              size="large"
              placeholder="Select level"
              style="width: 100%"
              v-model="kelas.level"
              @change="fetchDataDetail"
            >
              <a-select-option
                v-for="(level, i) in levels"
                :value="level"
                :key="i"
                >{{ level }}</a-select-option
              >
            </a-select>
          </a-col>
          <a-col :lg="5" :sm="24">
            <!-- <div>SYMBOL</div> -->
            <a-select
              size="large"
              placeholder="Select symbol"
              style="width: 100%"
              v-model="kelas.symbol"
              @change="fetchDataDetail"
            >
              <a-select-option
                v-for="(symbol, i) in symbols"
                :value="symbol"
                :key="i"
                >{{ symbol }}</a-select-option
              >
            </a-select>
          </a-col>
          <a-col :lg="14">
            <div
              class="d-md-flex justify-content-lg-end ml-auto text-center"
              :style="{
                'margin-top': isMobile ? '1em' : null,
              }"
            >
              <a-switch
                @change="showMuridSesi = !showMuridSesi"
                :disabled="disableShowDetails"
                :checked="showMuridSesi"
              />
              <span class="ml-2"
                >{{ showMuridSesi ? "Hide" : "Show" }} Participants List</span
              >
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="[16, 16]">
          <a-col
            :md="24"
            v-if="showMuridSesi && !isDesktop"
            v-sticky="{ zIndex: 99, stickyTop: 10, disabled: !isDesktop }"
          >
            <div class="sesiDrawer p-4 scroll-custom">
              <ParticipantEkskulList
                :isRemoved="isRemoved"
                @removeSavedStudents="removeSavedStudents"
                @showConfirmAddTrue="showConfirmAdd = true"
                :daftarParticipants="daftarParticipants"
                :newParticipants="selectedStudents"
              />
            </div>
          </a-col>
          <a-col
            class="colTable"
            :lg="showMuridSesi ? 16 : 24"
            :md="24"
            :order="2"
          >
            <a-table
              class="table-scroll"
              :pagination="{ pageSize: 30, hideOnSinglePage: true }"
              :columns="column"
              :data-source="dataTable"
              :loading="loading.loadingTable"
              :row-selection="rowSelection"
              :scroll="{ x: 100 }"
              bordered
            >
              <!-- <a
                  slot="Name"
                  class="text-primary"
                  slot-scope="text, record"
                  @click.prevent="showDrawer(record.key)"
                  style="width:120px;"
                >{{ text }}</a> -->
            </a-table>
          </a-col>
          <a-col
            :lg="8"
            v-if="showMuridSesi && isDesktop"
            v-sticky="{ zIndex: 99, stickyTop: 10, disabled: !isDesktop }"
          >
            <div class="sesiDrawer p-4 scroll-custom">
              <ParticipantEkskulList
                :isRemoved="isRemoved"
                @removeSavedStudents="removeSavedStudents"
                @showConfirmAddTrue="showConfirmAdd = true"
                :daftarParticipants="daftarParticipants"
                :newParticipants="selectedStudents"
              />
            </div>
          </a-col>
        </a-row>
      </div>
    </template>
  </div>
</template>
<script>
import VueSticky from 'vue-sticky'
const column = [
  {
    title: 'No',
    // width: '5%',
    width: 80,
    dataIndex: 'No',
    key: 'No',
    align: 'center',
  },
  {
    title: 'Name',
    // width: '100%',
    width: 370,
    dataIndex: 'Name',
    key: 'Name',
    ellipsis: true,
  },
  {
    title: 'Gender',
    // width: '100%',
    width: 370,
    dataIndex: 'Gender',
    key: 'Gender',
  },
]

export default {
  components: {
    ParticipantEkskulList: () => import('@/components/app/ParticipantEkskulList'),
  },
  directives: {
    sticky: VueSticky,
  },
  // beforeRouteLeave (to, from, next) {
  //   if (this.isEdit) {
  //     const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
  //     if (answer) {
  //       window.removeEventListener('beforeunload', null)
  //       next()
  //     } else {
  //       // console.log(to, from)
  //       this.$store.commit('menu/SET_STATE', {
  //         currentMenu: ['Reports Teacher'],
  //       })
  //       next(false)
  //     }
  //   } else {
  //     window.removeEventListener('beforeunload', null)
  //     next()
  //   }
  // },
  // beforeDestroy() {
  //   window.removeEventListener('beforeunload', null)
  // },
  data() {
    return {
      selectedStudents: [],
      daftarKelas: [],
      daftarMurid: [],
      daftarParticipants: [],
      kelas: {
        level: '',
        symbol: '',
      },
      showConfirmAdd: false,
      showConfirmRemove: false,
      editPH: false,
      modalVisible: false,
      visibleDrawer: false,
      disableSelectType: false,
      disabledMenu: false,
      loading: {
        confirmLoading: false,
        loadingCancel: false,
        loadingTable: false,
        loadingEditReport: false,
        loadingRefresh: false,
        updateLoading: false,
      },
      dataTable: [],
      dataDetail: {},
      dataDrawer: {},
      chartData: {
        labels: [],
        series: [],
      },
      savedData: [],
      reportType: 'knowledge',
      reportText: {
        knowledge: 'Knowledge',
        skill: 'Skill',
      },
      column,
      isEdit: false,
      generalData: {},
      kdColumn: {
        title: 'KD',
        dataIndex: 'kd1',
        code: 'KD',
        key: 'KD',
        width: 400,
      },
      reportTime: ['mid'],
      modalConfirmUpdateReport: false,
      notes: {
        skill: [{
          code: 'Pra',
          full: 'Practical',
        }, {
          code: 'Pro',
          full: 'Project',
        }, {
          code: 'Oth',
          full: 'Others',
        }],
        knowledge: [{
          code: 'W',
          full: 'Written',
        }, {
          code: 'A',
          full: 'Assignments',
        }, {
          code: 'O',
          full: 'Oral',
        }],
      },
      showMuridSesi: true,
      kdLength: 0,
      disableShowDetails: false,
      removedStudentsData: [],
      isRemoved: false,
    }
  },
  methods: {
    handleOkRemoveParticipants() {
      this.loading.updateLoading = true
      this.isRemoved = true
      this.$store.dispatch('extracurricular/REMOVE_STUDENTS_BY_ID_EKSKUL', {
        idEkskul: this.generalData.ekstrakurikuler.id,
        removedStudentsData: this.removedStudentsData,
      })
        .then(isDone => {
          setTimeout(() => {
            this.$notification.success({
              message: 'Successfuly removed ' + this.removedStudentsData.length + ' participants on ' + this.generalData.ekstrakurikuler.nama + '.',
            })
            this.removedStudentsData = []
            this.isRemoved = false
            // this.modalConfirmUpdateReport = false
            // resolve(data.flag)
          }, 1000)
          this.fetchDataDetail()
        })
    },
    removeSavedStudents({ removedStudentsData }) {
      this.showConfirmRemove = true
      this.removedStudentsData = removedStudentsData
    },
    handleOkAddParticipants() {
      const savedNewParticipantsData = this.selectedStudents.map(student => {
        return {
          id_kelas_tahun_ajaran: student.idKelasTahunAjaran,
          id_murid: student.id,
          nama: student.Name,
        }
      })
      this.loading.updateLoading = true
      this.$store.dispatch('extracurricular/ADD_NEW_PARTICIPANTS_BY_ID_EKSKUL', {
        idEkskul: this.generalData.ekstrakurikuler.id,
        savedNewParticipantsData,
      })
        .then(is200 => {
          if (is200) {
            setTimeout(() => {
              this.$notification.success({
                message: 'Successfuly add new participants on ' + this.generalData.ekstrakurikuler.nama + '.',
              })
              this.loading.updateLoading = false
              // this.modalConfirmUpdateReport = false
              // resolve(data.flag)
            }, 1000)
          } else {
            this.loading.updateLoading = false
            // this.modalConfirmUpdateReport = false
            this.$notification.error({
              message: 'Sorry.',
              description: 'There must be some error in the server. Please try again.',
            })
          }
          this.fetchDataDetail()
        })
    },
    handleChangeKdValue(value = '', data, textData) {
      // console.log(textData, value, data, 'ini')
      const newData = [...this.dataTable]
      const target = newData.find(item => data.key === item.key)
      if (target) {
        target[textData.dataIndex] = {
          ...target[textData.dataIndex],
          data: value,
        }
        this.savedData = newData
      }
    },
    showDrawer(idMurid) {
      this.$store.dispatch('report/GET_SESI_BY_ID_MURID', { idMurid, idMapel: this.$route.params.idMapel })
        .then(data => {
          // console.log(data)
          const labels = []
          const series = []
          data.studentsChart.dataChart.forEach((el, index) => {
            labels.push(`#${++index}`)
            series.push(el.tugas_nilai)
          })
          const newData = [...this.dataTable]
          const target = newData.find(item => idMurid === item.key)
          this.dataDrawer = {
            ...target,
            ...data,
          }
          // console.log(target)
          this.chartData.labels = labels
          this.chartData.series[0] = series
          console.log(this.chartData)
          // this.dataDrawer = data
          this.visibleDrawer = true
        })
      // const newData = [...this.dataTable]
      // const target = newData.filter(item => key === item.key)[0]
      // console.log(target)
      // const labels = []
      // const series = []
      // target.ListAssignment.forEach((el, index) => {
      //   labels.push(`#${++index}`)
      //   series.push(el.tugas_nilai)
      // })

      // this.chartData.labels = labels
      // this.chartData.series[0] = series
      // console.log(this.chartData)
      // this.dataDrawer = target
      // this.visibleDrawer = true
    },
    startEdit() {
      this.isEdit = true
      this.disableSelectType = true
      this.disabledMenu = true
    },
    cancelEdit() {
      this.isEdit = false
      this.fetchDataDetail()
    },
    saveReport() { this.modalConfirmUpdateReport = true },
    refreshTable() {
      this.loading.loadingRefresh = true
      this.fetchDataDetail()
    },
    async fetchGeneralData() {
      try {
        const { generalData, dataKelas, tahunAjaran } = await this.$store.dispatch('extracurricular/FETCH_GENERAL_DATA_PEMBINA_BY_ID_PEMBINA', { idGuru: this.user.id })
        this.generalData = {
          ...generalData,
          tahunAjaran,
        }
        this.daftarKelas = dataKelas.map(kelas => {
          return {
            ...kelas,
            tingkat: kelas.level.nama,
          }
        })
        this.kelas.level = this.levels[0]
        this.kelas.symbol = this.symbols[0]
        this.fetchDataDetail()
        // this.fetchDataParticipants()
      } catch (err) {
        console.log(err)
      }
    },
    async fetchDataParticipants() {
      try {
        const dataParticipants = await this.$store.dispatch('extracurricular/GET_EXTRACURRICULAR_PARTICIPANTS_BY_ID_EKSKUL', { idEkskul: this.generalData.ekstrakurikuler.id })
        this.daftarParticipants = dataParticipants
        this.dataTable = this.daftarMurid.map((murid, i) => {
          const isParticipant = this.daftarParticipants.find(participant => participant.kelas_tahun_ajaran.murid.id === murid.id)
          const data = {
            key: murid.id,
            No: i + 1,
            Name: murid.nama,
            Class: this.kelas.level + '-' + this.kelas.symbol,
            idKelasTahunAjaran: murid.kelas_tahun_ajarans[0].id,
            id: murid.id,
            isParticipant: Boolean(isParticipant),
            Gender: murid.jenis_kelamin === 'Perempuan' ? 'Female' : murid.jenis_kelamin === 'Laki-Laki' ? 'Male' : '-',
          }
          this.loading.updateLoading = false
          this.loading.loadingTable = false
          this.loading.loadingRefresh = false
          this.disableSelectType = false
          this.disabledMenu = false
          this.disableShowDetails = false
          return data
        })
        this.selectedStudents = this.selectedStudents.filter(data => !this.daftarParticipants.find(participant => participant.kelas_tahun_ajaran.murid.id === data.key))
      } catch (err) {
        console.log(err)
      }
    },
    async fetchDataDetail() {
      try {
        this.loading.updateLoading = true
        this.loading.loadingTable = true
        this.disableSelectType = true
        this.disabledMenu = true
        // this.showMuridSesi = false
        this.showConfirmRemove = false
        this.showConfirmAdd = false
        this.disableShowDetails = true
        this.daftarMurid = await this.$store.dispatch('extracurricular/GET_MURID_BY_ID_KELAS', { idKelas: this.idKelasModel })
        this.fetchDataParticipants()
      } catch (err) {
        console.log(err)
      }
    },
    changeCode(code, index) {
      let codeIndex = ''
      switch (code) {
        case 'written' + index:
          codeIndex = 'written'
          break
        case 'assignment' + index:
          codeIndex = 'assignment'
          break
        case 'oral' + index:
          codeIndex = 'oral'
          break
        case 'practical' + index:
          codeIndex = 'practical'
          break
        case 'project' + index:
          codeIndex = 'project'
          break
        case 'others' + index:
          codeIndex = 'others'
          break
        default:
          codeIndex = ''
          break
      }

      return codeIndex
    },
    handleReportType(e) {
      const delay = t => new Promise(resolve => setTimeout(resolve, t))
      this.loading.loadingTable = true
      this.reportType = e.target.value
      this.reportText[this.reportType] = 'Please Wait...'
      delay(100).then(() => {
        this.reportText[this.reportType] = this.firstLetterUpperReportType
        this.loading.loadingTable = false
        this.fetchDataDetail({})
      })
    },
    onCloseDrawer(payload) {
      if (payload) {
        this.chartData.labels = []
        this.chartData.series = []
        this.visibleDrawer = false
      }
    },
    color(nilai) {
      // console.log(nilai)
      if (nilai < 25) {
        return '#FF0000'
      } else if (nilai >= 25 && nilai < 50) {
        return '#FF8811'
      } else if (nilai >= 50 && nilai < 75) {
        return '#F9E900'
      } else if (nilai >= 75 && nilai < 100) {
        return '#7ABE28'
      } else {
        return '#41B883'
      }
    },
    onSelectChange(key, datas) {
      const oldData = this.selectedStudents.filter(student => student.Class !== this.kelas.level + '-' + this.kelas.symbol)
      // console.log(oldData)
      // this.selectedStudents =
      // console.log('selectedRowKeys changed: ', datas)
      const newData = datas.filter(data => !this.daftarParticipants.find(participant => participant.kelas_tahun_ajaran.murid.id === data.key))
      this.selectedStudents = oldData.concat(newData)
    },
  },
  computed: {
    selectedStudentsKey() {
      const arr1 = this.daftarParticipants.map(participant => { return participant.kelas_tahun_ajaran.murid.id })
      // console.log(this.selectedStudents, 'masuk')
      const arr2 = this.selectedStudents.map(student => { return student.key })
      return arr1.concat(arr2)
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedStudentsKey,
        onChange: this.onSelectChange,
        getCheckboxProps: record => ({
          props: {
            disabled: record.isParticipant, // Column configuration not to be checked
            // name: record.name,
          },
        }),
      }
    },
    idKelasModel() {
      return this.daftarKelas.find(kelas => kelas.tingkat === this.kelas.level && kelas.simbol === this.kelas.symbol).id
    },
    symbols() {
      let symbols = []
      const kelasByLevel = this.daftarKelas.filter(kelas => kelas.tingkat === this.kelas.level)
      if (kelasByLevel.length) symbols = kelasByLevel.map(kelas => { return kelas.simbol })
      return symbols
    },
    levels() {
      const levels = []
      this.daftarKelas.forEach(kelas => {
        if (!levels.length) levels.push(kelas.tingkat)
        else {
          const isExist = levels.find(level => level === kelas.tingkat)
          if (!isExist) levels.push(kelas.tingkat)
        }
      })
      return levels
    },
    user() { return this.$store.state.user.user },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isDesktop() {
      // console.log(!this.$store.getters.isMobile && !this.$store.getters.isTablet)
      return !this.$store.getters.isMobile && !this.$store.getters.isTablet
    },
    firstLetterUpperReportType() {
      const first = this.reportType.split('')[0].toUpperCase()
      const withoutFirstLetter = this.reportType.split('').slice(1).join('')
      return first + withoutFirstLetter
    },
  },
  created() {
    this.fetchGeneralData()
    // this.fetchDataDetail()
  },
  watch: {
    reportTime(newVal, oldVal) {
      this.fetchDataDetail()
    },
    showMuridSesi(newVal) {
      // console.log(this.kdLength)
      // if (newVal) {
      //   this.column = this.column.map((col, i) => {
      //     return {
      //       ...col,
      //       fixed: this.kdLength > 1 && i <= 1 ? 'left' : null,
      //     }
      //   })
      // } else {
      //   this.column = this.column.map((col, i) => {
      //     return {
      //       ...col,
      //       fixed: this.kdLength > 1 && i <= 1 ? 'left' : null,
      //     }
      //   })
      // }
    },
    // isEdit(newVal) {
    //   if (newVal) {
    //     window.addEventListener('beforeunload', function(event) {
    //       event.returnValue = 'Are you sure to leave this page? You have undone form here.'
    //     })
    //   } else {
    //     window.removeEventListener('beforeunload', null)
    //   }
    // },
  },
}
</script>
<style lang="scss">
.addEkskulParticipant {
  .coverDetail {
    width: 100%;
    min-height: 145px;
    background-color: var(--kit-color-primary);
    border-radius: 10px;
    padding: 40px;
    h2 {
      color: #ffffff;
    }
  }

  .contentDetail {
    width: 100%;
    min-height: 20vh;
    background-color: white;
    padding: 40px;
    z-index: 11;
    margin-top: -10px;
    border-radius: 10px;
    box-shadow: 0 0 10px #0419301a;
    margin-left: 1px;
    .value span {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .sesiDrawer {
      // width: 100%;
      // height: 100%;
      border: 1px solid #e8e8e8;
      height: 40rem;
      overflow-y: auto;
      overflow-x: hidden;
      border-radius: 5px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    kbd {
      background-color: #eee;
      border-radius: 3px;
      border: 1px solid #b4b4b4;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
        0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
      color: #333;
      display: inline-block;
      font-size: 0.85em;
      font-weight: 700;
      line-height: 1;
      padding: 2px 4px;
      white-space: nowrap;
    }
    .inputNilai {
      .ant-input-number-input-wrap {
        height: 100%;
        .ant-input-number-input {
          height: 100%;
        }
      }
    }

    .colTable {
      .table-scroll {
        .ant-spin-nested-loading .ant-spin-container .ant-table {
          overflow-x: auto;
          // .ant-table-row-cell-ellipsis {
          //   @media (max-width: 769px) {
          //     width: 200px;
          //   }
          // }
        }
      }
    }
  }
}
</style>
